import axios from "axios";
import constant from "../src/constant";

const axios_instance = axios.create({
  baseURL: `${constant.ImageS3URl}/api/`,
  timeout: 100000,
});

export async function authorizeMe(token) {
  axios_instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export async function SendOtp(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post("auth/send-otp", data)
      .then(function (response) {
        if (response?.data?.status) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function verifyOtp(data) {
  var response = await axios_instance.post("auth/otp-verify", data);
  return response;
}
export async function logout(data) {
  var response = await axios_instance.post("auth/logout-status", data);
  return response;
}

export async function getAppVersion() {
  var response = await axios_instance.get("auto-update/app-version");
  return response;
}

export async function saveAppVersion(data) {
  var response = await axios_instance.post("app-version", data);
  return response;
}

export async function forgetPassword(data) {
  var response = await axios_instance.post("auth/forgot-password", data);
  return response;
}
export async function updateUserPassword(data) {
  var response = await axios_instance.patch("auth/update-password", data);
  return response;
}

export async function profileList(data, token) {
  const defaultOptions = { headers: { Authorization: `Bearer ${token}` } };
  var response = await axios_instance.post(
    "get_all_account",
    data,
    defaultOptions
  );
  return response;
}

export async function generateToken(data, token) {
  const defaultOptions = { headers: { Authorization: `Bearer ${token}` } };
  var response = await axios_instance.post(
    "auth/generate-token",
    data,
    defaultOptions
  );
  return response;
}

export async function dashboard(data, pageNumber) {
  var response = await axios_instance.post(
    `dashboard?page=${pageNumber}`,
    data
  );
  return response;
}

export async function suggestionApi(pageNumber) {
  var response = await axios_instance.post(`our-suggestion?page=${pageNumber}`);
  return response;
}

export async function referralApi(data) {
  var response = await axios_instance.post(`referral_code`, data);
  return response;
}

export async function userFilters() {
  var response = await axios_instance.get(`filter-fetch`);
  return response;
}

export async function downloadUserBiodataById(id) {
  var response = await axios_instance.post(`biodata-download/${id}`);
  return response;
}

export async function home(pageNumber) {
  var response = await axios_instance.get(`home?page=${pageNumber}`);
  return response;
}

export async function profileVisitedUserList(data, pageNumber) {
  var response = await axios_instance.post(
    `get-profile-visited-users?page=${pageNumber}`,
    data
  );
  return response;
}

export async function unlockUserList(data, pageNumber) {
  var response = await axios_instance.post(
    `unlock-user-list?page=${pageNumber}`,
    data
  );
  return response;
}

export async function addToFavoriteList(data) {
  var response = await axios_instance.get(`add-to-favourite/${data.id}`);
  return response;
}
export async function getBanner() {
  var response = await axios_instance.get("get-banners");
  return response;
}

export async function sendInterestRequest(data) {
  var response = await axios_instance.get(`send-interest/${data.id}`);
  return response;
}
export async function phonepeStatus(data) {
  var response = await axios_instance.post(`phonepe-status`, data);
  return response;
}
export async function deleteInterestRequest(data) {
  var response = await axios_instance.post(`delete-interest/${data.id}`);
  return response;
}

export async function sendPhotoRequestApi(data) {
  var response = await axios_instance.get(`request-photo/${data.id}`);
  return response;
}

export async function me() {
  var response = await axios_instance.get(`me`);
  return response;
}

export async function interestAction(data) {
  var response = await axios_instance.post("interest-action", data);
  return response;
}

export async function interestList(data, pageNumber) {
  var response = await axios_instance.post(
    `interest-list?page=${pageNumber}`,
    data
  );
  return response;
}

export async function interestFavouriteList(data, pageNumber) {
  var response = await axios_instance.post(
    `interest-list-favourite?page=${pageNumber}`,
    data
  );
  return response;
}

export async function interestAcceptList(data, pageNumber) {
  var response = await axios_instance.post(
    `interest-list-accept?page=${pageNumber}`,
    data
  );
  return response;
}

export async function interestPendingList(data, pageNumber) {
  var response = await axios_instance.post(
    `interest-list-pending?page=${pageNumber}`,
    data
  );
  return response;
}

export async function interestRejectList(data, pageNumber) {
  var response = await axios_instance.post(
    `interest-list-reject?page=${pageNumber}`,
    data
  );
  return response;
}

export async function DashboardStatsAPI(data) {
  return new Promise((resolve, reject) => {
    axios_instance
      .post("/job/dashboard-stats", data)
      .then(function (response) {
        if (response?.data?.success) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export async function getStateListAPI(data) {
  var response = await axios_instance.get(`/get-state-list/${data.countryId}`);
  return response;
}
export async function getCityListAPI(data) {
  var response = await axios_instance.get(`/get-city-list/${data.stateid}`);
  return response;
}
export async function getTehsilListAPI(data) {
  var response = await axios_instance.get(`/get-tehsil-list/${data.cityid}`);
  return response;
}
export async function getCountryListAPI() {
  var response = await axios_instance.get(`get-country-list`);
  return response;
}

export async function getCurrentState() {
  var response = await axios_instance.get(`http://ip-api.com/json/`);
  return response;
}

export async function createCitytAPI(data) {
  var response = await axios_instance.post(`/form/create-city`, data);
  return response;
}

export async function signUp(data) {
  var response = await axios_instance.patch("update-user", data);
  return response;
}

export async function profileUpload(key, formData) {
  var response = await axios_instance.post(
    `/image/upload?type=${key}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response;
}
export async function IdUpload(idNumber, selectedIds, formData) {
  var response = await axios_instance.post(
    `/image/upload?type=id&idNumber=${idNumber}&selectedIds=${selectedIds}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return response;
}
// export async function biodataUpload(formData) {
//     var response = await axios_instance.post('/image/upload?type=birth', formData, { headers: { "Content-Type": "multipart/form-data", } });
//     return response;
// }

export async function getAllNotification(pageNumber) {
  var response = await axios_instance.post(
    `get-notification-list?page=${pageNumber}`,
    {}
  );
  return response;
}

export async function getPushNotification1(data) {
  var response = await axios_instance.post(
    `get-user-pust-notification-by-id `,
    data
  );
  return response;
}
export async function updateOtpToMA(id) {
  var response = await axios_instance.patch(
    `master-admin/update-otp-submitted/${id}`
  );
  return response;
}
export async function getPushNotification() {
  var response = await axios_instance.post(`get-user-pust-notification`, {});
  return response;
}
export async function getProfileById(data) {
  var response = await axios_instance.get(`get-profile/${data.id}`);
  return response;
}
export async function getProfiles(type) {
  var response = await axios_instance.post("get-profile-list", type);
  return response;
}
export async function getSubscriptionList() {
  var response = await axios_instance.get("get-pacakge-list");
  return response;
}
export async function getSpecialPackageList() {
  var response = await axios_instance.get("new_special-pacakge-list");
  return response;
}
export async function getSpecialPackage(id) {
  var response = await axios_instance.get(`get-pacakge-list-special/${id}`);
  return response;
}
export async function getVipPackageList() {
  var response = await axios_instance.get("new_vip-pacakge-list");
  return response;
}

export async function getSpecailVipPackageList(data) {
  var response = await axios_instance.post("new_get-pacakge-list", data);
  return response;
}

export async function getCurrentPackage() {
  var response = await axios_instance.get("my-pacakge");
  return response;
}
export async function createOrder(data) {
  var response = await axios_instance.post("payment/create-order", data);
  return response;
}
export async function PaymentVerification(data) {
  var response = await axios_instance.post("payment/verify-signature", data);
  return response;
}
export async function createPhonepePaymentLink(data) {
  var response = await axios_instance.post(
    "master-admin/create-phonepe-order",
    data
  );
  return response;
}
export async function SplPkgStatus(data) {
  var response = await axios_instance.post(
    `update-packages-status-button-single`,
    data
  );
  return response;
}

export async function login(data) {
  var response = await axios_instance.post("auth/login", data);
  return response;
}
export async function newAccount(data) {
  var response = await axios_instance.post("auth/new-account", data);
  return response;
}
export async function profileAction(data) {
  var response = await axios_instance.post("image/action", data);
  return response;
}
export async function search(data, pageNumber) {
  var response = await axios_instance.post(`search?page=${pageNumber}`, data);
  return response;
}
export async function unlock(data) {
  var response = await axios_instance.get(`unlock/${data.id}`);
  return response;
}
export async function unlockBlockedUser(data) {
  var response = await axios_instance.post(`unblock-user`, data);
  return response;
}

export async function getUpdateApp() {
  var response = await axios_instance.get("update-app-version");
  return response;
}

export async function getEmpListAPI() {
  return [
    {
      english: "Doctors",
      hindi: "डॉक्टर",
    },
    {
      english: "Engineers",
      hindi: "इंजीनियर",
    },
    {
      english: "Ca/Cs",
      hindi: "सी.ए. / सी.एस.",
    },
    {
      english: "Civil services (Upsc/State Psc)",
      hindi: "सिविल सेवा (Upsc/State Psc)",
    },
    {
      english: "Government / PSU",
      hindi: "सरकार / पीएसयू",
    },
    {
      english: "Private Job",
      hindi: "प्राइवेट नौकरी",
    },
    {
      english: "Defence",
      hindi: "भारतीय सेना",
    },
    {
      english: "Self Employed / Business",
      hindi: "स्व नियुक्त / व्यवसाय",
    },
    {
      english: "Freelancer",
      hindi: "फ्रीलांसर",
    },
    {
      english: "Looking For Job",
      hindi: "नौकरी की तलाश",
    },
    {
      english: "Not working",
      hindi: "काम नहीं कर रहे",
    },
    {
      english: "House Wife",
      hindi: "गृहिणी",
    },
    {
      english: "Retired",
      hindi: "सेवानिवृत्त",
    },
    {
      english: "Passed away",
      hindi: "गुजर गए",
    },
  ];
}

export async function getGoliveFormDetails() {
  var response = await axios_instance.get(`form-detail/go-live`);
  return response;
}

export async function getHobbbiesDetails() {
  var response = await axios_instance.get(`form-detail/get-hobbie-list`);
  return response;
}

export async function getProfileStats() {
  var response = await axios_instance.get("profile-data");
  return response;
}

export async function getMotherTongueList() {
  var response = await axios_instance.get(`form-detail/get-mother-tongue-list`);
  return response;
}
export async function getRashiList() {
  var response = await axios_instance.get("form-detail/getRaashiList");
  return response;
}
export async function getnakshtra(data) {
  var response = await axios_instance.get(
    `form-detail/get-sub-raashi-list/${data.id}`
  );
  return response;
}
export async function getSubsect(data) {
  var response = await axios_instance.get(`/get-sub-sect-list/${data}`);
  return response;
}
export async function getGotraList(data) {
  var response = await axios_instance.get(`/get-gotra-list/${data}`);
  return response;
}

export async function addGotra(data) {
  var response = await axios_instance.post(`/form/create-gotra-by-user`, data);
  return response;
}

export async function addSubSect(data) {
  var response = await axios_instance.post(`/form/create-sub-sect-user`, data);
  return response;
}

export async function getSecurityKey() {
  var response = await axios_instance.get(`/get-security-key`);
  return response;
}

export async function getPendingHistory() {
  var response = await axios_instance.get(`/get-referral/panding-userList `);
  return response;
}

export async function getSuccessHistory() {
  var response = await axios_instance.get(`/get-referral/history-userList`);
  return response;
}
export async function verifyReferral(data) {
  var response = await axios_instance.get(`/get-user/${data.referralId}`);
  return response;
}
export async function getProfileByIdPublic(data) {
  var response = await axios.get(
    `${constant.ImageS3URl}/api/public-profile/${data.id}`
  );
  return response;
}
export async function generatePaymentLink(data) {
  var response = await axios_instance.post("payment/create-payment-link", data);
  return response;
}

export async function requestBiodata(data) {
  var response = await axios_instance.post("Biodata-notification", data);
  return response;
}

export async function getMyOffers(id) {
  var response = await axios_instance.get(
    `master-admin/get-offers-status/${id}`
  );
  return response;
}
export async function getUniversalOffers() {
  var response = await axios_instance.post(
    `master-admin/get-pacakge-list-new`,
    {}
  );
  return response;
}

export async function getContact() {
  var response = await axios_instance.post(`master-admin/conatcts`);
  return response;
}
export async function blockedUser(data) {
  var response = await axios_instance.post(
    `master-admin/blocked-user-new`,
    data
  );
  return response;
}
export async function reportUser(data) {
  var response = await axios_instance.post(`master-admin/report-user`, data);
  return response;
}
export async function shadiDoneUser(data) {
  var response = await axios_instance.post(
    `master-admin/shadi-done-user`,
    data
  );
  return response;
}
export async function getShadiDoneUser() {
  var response = await axios_instance.post(`get-shadi-done-user`);
  return response;
}
export async function getProfileVisitHistory() {
  var response = await axios_instance.post(`get-visit-history`);
  return response;
}
export async function removeShadiDoneUserApi(data) {
  var response = await axios_instance.post(`remove-shadi-done-user`, data);
  return response;
}
export async function getBlockedUser() {
  var response = await axios_instance.post(`get-blocked-user-new`);
  return response;
}
export async function chnageLoginStatus() {
  var response = await axios_instance.post("otp-verified-button");
  return response;
}
export async function updateApprovalPopup(data) {
  var response = await axios_instance.post("update-approval-popup", data);
  return response;
}
export async function autoPackage() {
  var response = await axios_instance.get("get_auto_package_for_user");
  return response;
}
export async function unblockUser(data) {
  var response = await axios_instance.post(`master-admin/unblock-user`, data);
  return response;
}
export async function requestToDelete(data) {
  var response = await axios_instance.post(
    `master-admin/create-user-data-delete-request`,
    data
  );
  return response;
}
export async function applicationLogin() {
  var response = await axios_instance.put(
    `master-admin/update-web-app-for-app`
  );
  return response;
}
export async function websiteLogin() {
  var response = await axios_instance.put(
    `master-admin/update-web-app-for-website`
  );
  return response;
}
export async function phonePePaymentVerification(id, data) {
  var response = await axios_instance.post(`master-admin/payment/${id}`, data);
  return response;
}
export async function isPhonePeOn() {
  var response = await axios_instance.get(`master-admin/payment-method-status`);
  return response;
}
export async function birthPlaceRequestApi(data) {
  var response = await axios_instance.post(`birth-place-notification`, data);
  return response;
}
export async function motherMobileRequestApi(data) {
  var response = await axios_instance.post(`mother-mobile-notification`, data);
  return response;
}
export async function fatherMobileRequestApi(data) {
  var response = await axios_instance.post(`father-mobile-notification`, data);
  return response;
}
export async function alternateMobileRequestApi(data) {
  var response = await axios_instance.post(
    `alternate-mobile-notification`,
    data
  );
  return response;
}
export async function mobileRequestApi(data) {
  var response = await axios_instance.post(`mobile-notification`, data);
  return response;
}
export async function incomeRequestApi(data) {
  var response = await axios_instance.post(`Income-notification`, data);
  return response;
}
export async function createFieldData(data) {
  var response = await axios_instance.post(
    `master-admin/create-field-data`,
    data
  );
  return response;
}
export async function paymentError(data) {
  var response = await axios_instance.post(
    `payment/payment-error`,
    data
  );
  return response;
}
