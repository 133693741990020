import { useCallback, useEffect, useReducer, useState } from "react";
import AppReducer from "./AppReducer";
import AppContext from "./AppContext";
import {
  SET_USER_STATUS,
  SET_USER,
  SET_IS_LOGGED_In,
  SET_DO_IT_LATER,
  SET_NO_DELAY_POPUP,
  SET_FILTER,
  SET_NOTIFICATION,
  SET_POPUPS,
  SET_IS_UPDATE_AVAILABLE,
  SET_PACKAGES,
  SET_POPUP_CLOSE,
  SET_POPUP_OPEN,
  SET_INSTANT_POPUP,
  SET_UPLOAD,
  SET_BACK_COUNT,
  GET_PACKAGES,
  GET_KEY,
} from "./AppType";
import constant from "../constant";
import {
  authorizeMe,
  getAppVersion,
  getCurrentPackage,
  getSecurityKey,
  logout,
  me,
  saveAppVersion,
} from "../service";
import { useIonLoading } from "@ionic/react";
import { CapacitorUpdater } from "capacitor-updater";
import { PushNotifications } from "@capacitor/push-notifications";
import { useIonAlert } from "@ionic/react";

const default_popups = {
  isOpenProfile: false,
  isGoLive: false,
  isPartnerPreference: false,
  isFirstForm: false,
  isSecondForm: false,
  isThirdForm: false,
  isFourthForm: false,
  isFifthForm: false,
  isSubscribe: false,
};

const currentVersion = 80;

const AppState = (props) => {
  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonLoading();
  const [noChange] = useState("");

  let initialState = {
    user: undefined,
    userStatus: {
      language: constant.English,
      form: constant.SignUp,
      isPassword: false,
    },
    isLoggedIn: false,
    filter: localStorage.getItem("lastUsedFilter") || null,
    notification: false,
    package: false,
    isAppUpdating: false,
    isUpdateAvailable: false,
    key: null,
    currentVersion,
    popups: default_popups,
    doitlater: false,
    noDelayPopup: null,
    currentPackages: {},
    phonePe: {
      env: "PRODUCTION",
      merchantId: "JAINSHADIONLINE",
      appId: "a895e5f3-339d-4a2e-8904-e604235c3d65",
      merchantUserId: "JSM-USERAPP",
    },
    instantPopup: false,
    upload: {
      key: null,
      name: null,
    },
    backCount: 0,
  };

  useEffect(() => {
    handleRefresh();
    addListeners();
  }, [noChange]);

  const newVersionName = "0.3.90";
  const oldVersionName = "0.3.89";
  localStorage.setItem("appVersion", newVersionName);

  const saveAppVersionUser = async () => {
    let obj = {
      new_version_number: newVersionName,
      old_version_number: oldVersionName,
    };
    await saveAppVersion(obj);
  };

  const addListeners = async () => {
    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        if (
          notification?.body === "You are Approved by master admin" ||
          notification?.body ===
            "Request for publish profile is Approved by admin"
        )
          updateUser();
      }
    );
  };

  const forceUpdate = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let res = await getAppVersion();
        let version = await CapacitorUpdater.download({
          url: res?.data?.data?.url,
        });
        await CapacitorUpdater.set(version);
        resolve();
      } catch (e) {
        resolve();
      }
    });
  };

  useEffect(() => {
    if (window.location.pathname.includes("payment")) {
      setTimeout(() => {
        window.location.assign("");
      }, 1500);
    }
  }, [window.location.pathname]);

  const handleRefresh = async () => {
    if (JSON.parse(localStorage.getItem(constant.JSMUSERLOGGEDIN))) {
      setIsLoggedIn(true);
    }
    if (localStorage.getItem(constant.JSMUSER)) {
      authorizeMe(localStorage.getItem(constant.JSMUSER));
      await me()
        .then((response) => {
          response.data.data.user.token = localStorage.getItem(
            constant.JSMUSER
          );
          setUser(response?.data?.data?.user);
          // console.log("response?.data?.data?.user :", response?.data?.data?.user)
        })
        .catch((error) => {
          if (error?.response?.data?.errors === "User not found.") {
            // resetUser();
            setIsLoggedIn(false);
            localStorage.clear();
            dispatch({ type: SET_USER, payload: null });
            window.history.replaceState(null, "", "/");
            window.location.reload();
          }
        });
    } else {
      setUser(null);
    }
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setUserStatus = (userStatus) => {
    dispatch({ type: SET_USER_STATUS, payload: userStatus });
  };

  const setIsLoggedIn = (status) => {
    localStorage.setItem(constant.JSMUSERLOGGEDIN, status);
    dispatch({ type: SET_IS_LOGGED_In, payload: status });
  };

  const setUser = (user) => {
    if (user) {
      localStorage.setItem(constant.JSMUSER, user.token);
      setFilter(user?.filter || constant.FilterConst);
    }
    dispatch({ type: SET_USER, payload: user });
  };

  const setPackage = (packageData) => {
    dispatch({
      type: GET_PACKAGES,
      payload: packageData,
    });
  };

  const getSecurityKeyFn = useCallback(async () => {
    let resss = await getSecurityKey();
    dispatch({ type: GET_KEY, payload: resss?.data?.data?.key });
  }, [state.user]);

  useEffect(() => {
    if (state.isLoggedIn) {
      getSecurityKeyFn();
    }
  }, [getSecurityKeyFn]);

  useEffect(() => {
    if (state.isLoggedIn) {
      saveAppVersionUser();
    }
  }, [state.isLoggedIn]);

  const resetUser = async () => {
    await logout({ id: state?.user?.id });
    setIsLoggedIn(false);
    localStorage.clear();
    dispatch({ type: SET_USER, payload: null });
    window.history.replaceState(null, "", "/");
    window.location.reload();
    // window.location.assign('/');
  };

  const setFilter = (filter) => {
    dispatch({ type: SET_FILTER, payload: filter });
  };

  const setNotification = (status) => {
    dispatch({ type: SET_NOTIFICATION, payload: status });
  };

  const setPopupClose = (status) => {
    dispatch({ type: SET_POPUP_CLOSE, payload: status });
  };

  const setPopupOpen = (status) => {
    dispatch({ type: SET_POPUP_OPEN, payload: status });
  };

  const setBackCount = (status) => {
    dispatch({ type: SET_BACK_COUNT, payload: status });
  };

  const setLoading = (status) => {
    if (status) {
      present({
        message: "Loading...",
        spinner: "circles",
      });
    } else {
      dismiss();
    }
  };

  const updateUser = async () => {
    await me()
      .then((response) => {
        response.data.data.user.token = localStorage.getItem(constant.JSMUSER);
        setUser(response?.data?.data?.user);
      })
      .catch((error) => {
        if (error?.response?.data?.errors === "User not found.") {
          resetUser();
        }
      });
  };

  const setPopups = (name, status, showNow = false) => {
    if (status) resetPopups();
    let new_popups = JSON.parse(JSON.stringify(default_popups));
    new_popups[name] = status;
    dispatch({ type: SET_POPUPS, payload: new_popups });
    if (showNow) dispatch({ type: SET_NO_DELAY_POPUP, payload: name });
    else dispatch({ type: SET_NO_DELAY_POPUP, payload: null });
  };

  const resetPopups = () => {
    // var id = window.setTimeout(function () { }, 0);
    // while (id--) { window.clearTimeout(id); }
  };

  const setDoItLater = () => {
    if (!state.noDelayPopup) {
      resetPopups();
      dispatch({ type: SET_DO_IT_LATER, payload: true });
    } else {
      dispatch({ type: SET_NO_DELAY_POPUP, payload: null });
    }
    setInstantPopup(false);
  };

  const setNoDelayPopup = (name) => {
    resetPopups();
    dispatch({ type: SET_NO_DELAY_POPUP, payload: name });
  };

  const setInstantPopup = (name) => {
    dispatch({ type: SET_INSTANT_POPUP, payload: name });
  };
  const setUpload = (name) => {
    dispatch({ type: SET_UPLOAD, payload: name });
  };

  return (
    <AppContext.Provider
      value={{
        user: state.user,
        currentPackages: state.currentPackages,
        userStatus: state.userStatus,
        isLoggedIn: state.isLoggedIn,
        filter: state.filter,
        notification: state.notification,
        package: state.package,
        currentVersion: state.currentVersion,
        isUpdateAvailable: state.isUpdateAvailable,
        popups: state.popups,
        doitlater: state.doitlater,
        noDelayPopup: state.noDelayPopup,
        instantPopup: state.instantPopup,
        upload: state.upload,
        backCount: state.backCount,
        key: state.key,
        setUpload,
        setPackage,
        setInstantPopup,
        setNoDelayPopup,
        setDoItLater,
        resetPopups,
        setPopups,
        setUserStatus,
        setLoading,
        setIsLoggedIn,
        setPopupClose,
        setPopupOpen,
        setUser,
        setFilter,
        setNotification,
        updateUser,
        resetUser,
        forceUpdate,
        setBackCount,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
export default AppState;
